<template>
  <div class="mainbox">
    <img class="bg" src="@/assets/img/activeTwelve/bg99.png" alt="" />

    <div @click="close()" style="cursor: pointer;" class="close"> <img src="@/assets/img/activeTwelve/bg88.png"
        alt="" />
    </div>
    <div class="logo">
      <img :src="copyright.logo" alt="">
    </div>
    <div class="terMsg">
      <div class="ter_img">
        <div>
          <img :src="teacher.terImg" alt="">
        </div>

      </div>
      <div class="ter_top">
        <div>{{ teacher.isRecommend ? teacher.userName : teacher.nickname }}</div>
        <div v-if="teacher.jobTitle">{{ teacher.jobTitle }}</div>
      </div>
      <div class="ter_slogan">
        {{ teacher.terSlogan ? teacher.terSlogan : '个性教学，智慧引领，成就您的学习之旅' }}
      </div>
      <div v-if="teacher.qwQrCode" class="ter_qrcode">
        <div class="ter_qrcode_img">
          <img :src="teacher.qwQrCode" alt="">
        </div>
        <div class="ter_qrcode_text">扫一扫添加讲师企微，与讲师零距离接触，
          获取更多学习资源！</div>
      </div>
    </div>
  </div>
</template>
<script>

import Vue from 'vue'
import { getInfo, getDomain, getToken } from '@/api/cookies'

import { TeacherClient } from '@/api/teacherClient'
const teacherClient = new TeacherClient()
export default {
  components: {},
  props: [
    'lectureId'
  ],
  data() {
    return {

      teacher: {}
    }
  },

  async created() {
    this.copyright = getDomain()
    this.userInfo = getInfo()
    const ress = await teacherClient.getPcById({ lectureId: this.lectureId })
    this.teacher = ress.data.teacher
  },


  methods: {
    close() {
      this.$emit('isok')
    }

  }
}
</script>
<style lang="less" scoped>
.mainbox {
  position: relative;
  width: 580px;
  // height: 470px;
  background: linear-gradient(136deg, #F66B23 0%, #FF5E51 100%);
  border-radius: 30px 30px 30px 30px;
  padding: 23px;

  .close {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 23px;
    height: 23px;
    border-radius: 3px 3px 3px 3px;
    font-size: 46px;
    color: #fff;

    img {
      width: 23px;
    }
  }

  .logo {
    margin-top: 28px;
    height: 52px;
    margin-left: 10px;

    img {
      height: 52px;
    }
  }

  .terMsg {
    width: 536px;
    // height: 320px;
    background: #FFF9F3;
    border-radius: 20px 20px 20px 20px;
    margin-top: 31px;
    position: relative;
    padding-top: 25px;

    .ter_img {
      position: absolute;
      width: 99px;
      height: 99px;
      background: #FFF9F3;
      border-radius: 14px 14px 14px 14px;
      right: 30px;
      top: -34px;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 99;


      div {
        width: 91px;
        height: 91px;
        border-radius: 10px 10px 10px 10px;
        overflow: hidden;
      }

      img {
        width: 100%;
        height: fit-content;

      }
    }

    .ter_top {
      display: flex;
      align-items: center;
      margin-left: 30px;

      >div:nth-child(1) {
        font-family: PingFang SC, PingFang SC;
        font-weight: bold;
        font-size: 28px;
        color: #FF5E51;
      }

      >div:nth-child(2) {
        padding: 3px 14px;
        border-radius: 15px;
        border: 1px solid #FF5E51;
        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 16px;
        color: #FF5E51;
        margin-left: 10px;
      }
    }

    .ter_slogan {

      font-family: PingFang SC, PingFang SC;
      font-weight: 400;
      font-size: 20px;
      color: #333333;
      margin-top: 20px;
      margin-left: 30px;
      padding-bottom: 58px;
      padding-right: 40px;
    }

    .ter_qrcode {
      width: 536px;
      height: 149px;
      background: #FFFFFF;
      border-radius: 20px 20px 20px 20px;
      // margin-top: 58px;
      display: flex;
      align-items: center;
      padding: 24px 36px;


      .ter_qrcode_img {
        width: 100px;
        height: 100px;
        margin-right: 20px;
        flex-shrink: 0;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .ter_qrcode_text {

        font-family: PingFang SC, PingFang SC;
        font-weight: 400;
        font-size: 18px;
        color: #333333;
      }
    }
  }
}

.bg {
  position: absolute;
  width: 249px;
  top: 25px;
  right: 66px;
}
</style>