/*
 * @Author: 
 * @Date: 2023-02-21 09:49:56
 * @LastEditTime: 2023-06-08 09:16:56
 * @LastEditors: 王有毒
 * @Description: 
 * @FilePath: \edub_tob_pc\src\api\teacherClient.js
 * 
 */
import request from "@/common/utils/request.js";
export class TeacherClient {
  /* --------------------讲师------------------------ ---------------------------------------------------------------------------------- */
  /**pc端讲师列表
   */
  async teacherPageList(pageSize, pageNum, tType, isRecommend) {
    let data = await request({
      url: "/lecturer/teacher/selectPCPageList",
      method: "GET",
      params: {
        pageSize: pageSize,
        pageNum: pageNum,
        tType: tType,
        isRecommend: isRecommend,
      },
    });
    return data;
  }
  /**讲师详情*/
  async getTeacher(id) {
    let data = await request({
      url: "/stu/edu/teacher/getTeacher",
      method: "GET",
      params: {
        id: id,
      },
    });
    return data;
  }
  /* --------------------PC端名片老师------------------------ ---------------------------------------------------------------------------------- */
  /**讲师详情*/
  async getSysUserTeacher(userid) {
    let data = await request({
      url: "/stu/studentUser/getSysUserTeacher",
      method: "GET",
      params: {
        userid: userid,
      },
    });
    return data;
  }
  /* ========================师资力量========================================================================================================== */
  /* 师资力量分页列表 */
  async selectPCPageList(params) {
    let data = await request({
      url: "/lecturer/teacher/selectPCPageList",
      method: "GET",
      params,
    });
    return data;
  }
  async selectNewPCPageList(params) {
    let data = await request({
      url: "/lecturer/teacher/selectNewPCPageList",
      method: "GET",
      params,
    });
    return data;
  }
  /* 师资力量详情 */
  async getPcById(params) {
    let data = await request({
      url: "/lecturer/teacher/getPcById",
      method: "GET",
      params,
    });
    return data;
  }
  /* 师资详情答疑列表 */
  async selectStuQuestionsList(params) {
    let data = await request({
      url: "/kecheng/terquestions/selectStuQuestionsList",
      method: "GET",
      params,
    });
    return data;
  }
  /* 师资详情答疑列表 */
  async getTerPcEvaluateList(params) {
    let data = await request({
      url: "/kecheng/stuEvaluate/getTerPcEvaluateList",
      method: "GET",
      params,
    });
    return data;
  }
  /**
   * 查询机构讲师负责的课程
   * @param terId 讲师ID
   */
  async getCourseTerList(params) {
    let data = await request({
      url: "/kecheng/courseAll/getCourseTerList",
      method: "GET",
      params,
    });
    return data;
  }
}
