<template>
  <div class="bigBox">

    <div class="videoBar">

      <!-- 直播 -->
      <div class="mainvideo">
        <div class="mainvideo_top">
          <div class="ellipsis">{{ detail.zjTitle }}</div>
          <div>
            <div class="time">
              <i class="iconfont icon-a-Group1339"></i>
              直播时间：{{ detail.startTime | format("yyyy.MM.dd HH:mm")
              }}
            </div>
            <div class="ter-list">
              <div v-if="detail.tImg" class="terbox">
                <el-image class="ter-img" :src="detail ? detail.tImg : ''" fit="cover"></el-image>
              </div>
              <span class="ter">讲师：{{ detail ? detail.terName : "" }}</span>
            </div>
          </div>
        </div>
        <div id="J_prismPlayer" />
      </div>

      <!-- 聊天 -->
      <div class="NIM_wrap">
        <div class="title">讨论区</div>
        <div id="msgBlock" class="information">
          <div v-for="(item, index) in list" :key="index" class="white">

            <div class="wrap" :class="item.bizFromId == userInfo.wxStuId ? 'dis-end' : 'dis-start'
              ">

              <div class="wrap1">
                <div style="width: 36px;height: 36px;margin-right: 10px;">
                  <img
                    src="https://staticfile.xlcwx.com/userfiles/202109/523e99ee8422345231b0b4c80023032560e20210923105521224.png"
                    alt="">
                </div>
                <div>
                  <div class="formNick">{{ item.bizFromName }}</div>
                  <div class="text">{{ item.text }}</div>
                </div>

              </div>

            </div>

          </div>
        </div>
        <div v-if="imQunId" class="barrageInput">
          <el-input v-model="barrage" class="input" placeholder="请输入" onkeydown="if (event.keyCode===13){return false;}"
            @keyup.enter.native="sendMessage">
            <template slot="append">
              <div @click="sendMessage">发送</div>
              <!-- <el-button size="mini" type="primary" class="sendBtn"></el-button> -->
            </template>
          </el-input>
          <!-- <emoji class="mt-10" @isOk="isOk" :ifIndex="true" /> -->

        </div>
        <div v-else class="barrageInput">
          <el-input v-model="barrage" class="input" placeholder="暂时无法发言" disabled />
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import { Know } from '@/api/know.js'
import Bus from '@/api/bus'
import Vue from 'vue'
import { getInfo } from '@/api/cookies'
const know = new Know()
import emojiList from '@/assets/json/emoji.json'
import emoji from '@/components/emoji.vue'
const NIM = require('@/assets/js/NIM_Web_SDK_v8.9.0.js')
import {
  joinGroups,
  getGroupChatLog,
  queryGroupMsg,
  sendMessage,
} from '@/api/home'
export default {
  components: {
    emoji,
  },
  props: [
    'detail',
  ],
  data() {
    return {
      textVal: '',
      id: null, // 公开课主键ID
      courseDetail: null,
      recommendList: [],
      recommendedLive: [],
      userInfo: null,
      zbStartTime: null,
      massageList: {}, // 消息列表
      imQunId: null,
      barrage: '', // 输入框
      inputInfo: false,
      massage: [],
      emojiList: [], // 表情列表
      token: '',
      req: '',
      items: {},
      list: [],
      im_websocket: '',
      ws_server_url: '',
      im_time_interval: '',
      count: 0,
      stuId: '', // 观看学生用户id
      tenantId: '', // 机构id
      userId: '', // 邀请人用户id'
      status: '', // 1 学生分享  2老师分享 0 主动进去
      time: 0,
      timer: null,
      timers: null,
      shareShow: false,
      QRCodeUrl: '',
      PCtext: '',
      test1: null,
      player: null,
      currentVideo: {},
      firstPlayFlag: true,
      wssresult: '',
    }
  },
  async created() {
    console.log(this.detail, 'detaildetail');
    this.courseDetail = this.detail

    const obj = {
      accessKeyId: 'JXYqi1xr89ekrnly17sknari',
      accessKeySecret: 'w4fqam7qpjv5f8406e369njcvfmiga',
    }
    const jsonStr = JSON.stringify(obj)
    this.wssresult = btoa(jsonStr)
    this.userInfo = await getInfo()
    console.log(this.userInfo, ';this.userInfo');
    this.ws_server_url = `wss://gateway.juxue.cloud/imserver/ws/${this.userInfo.wxStuId}/${this.wssresult}`
    this.imQunId = this.detail.imQunId

    this.id = this.detail.id

    // know.updatePublicityClassClicks(this.id)
  },
  async mounted() {
    this.userInfo = await getInfo()
    if (this.userInfo) {

      this.stuId = this.userInfo.id
      this.tenantId = this.userInfo.tenantId
      this.im_connect()

      this.joinGroup()
      await this.aliplayer(this.detail)
      this.stuLiveArriveService()

    } else {
      Vue.prototype.goLoginView(true)
    }
    this.emojiList = emojiList
  },


  methods: {

    // 阿里初始化
    async aliplayer(item) {
      console.log(item.wzUrl, 'wzUrlwzUrl')
      const _this = this
      // 阿里录播
      this.player = new Aliplayer(
        {
          id: 'J_prismPlayer',
          source: item.obsVideoUrl,
          isLive: true,
        },

      )


    },



    /* 点击公开课添加观看记录 */
    stuLiveArriveService() {
      const data = {
        playbackId: this.detail.id,
        type: 1,

      }
      know.stuLiveArriveService(data)
    },





    /* ============================网易云信=============================== */

    async joinGroup() {

      await joinGroups({
        bizGroupId: this.imQunId,
        bizUserId: this.userInfo.wxStuId,
        bizUserName: this.userInfo.userName,
      }).then(async (res1) => {

        if (res1.code == 0) {
          await getGroupChatLog({ bizGroupId: this.imQunId }).then((res2) => {
            if (res2.code == 0) {
              this.list = res2.data
              this.scrollbottom()
            }
          })
        } else {
        }
      })
    },

    // 发送信息
    async sendMessage(event) {
      event.preventDefault() // 阻止浏览器默认换行操作
      await sendMessage({
        type: 1,
        bizFrom: this.userInfo.wxStuId,
        bizTo: this.imQunId,
        text: this.barrage,
      })
      this.barrage = ''
    },

    im_connect() {
      console.log(12)
      try {
        this.count = this.count + 1
        this.im_websocket = new WebSocket(this.ws_server_url)
      } catch (evt) {
        console.log('new WebSocket error:' + evt.data)
        this.im_websocket = null
      }
      this.im_websocket.onopen = this.im_onOpen
      this.im_websocket.onclose = this.im_onClose
      this.im_websocket.onmessage = this.im_onMessage
      this.im_websocket.onerror = this.im_onError
    },
    im_onOpen(evt) {

      this.im_websocket.send(
        JSON.stringify({
          // 连接成功将，类型回传给服务端
          requestId: this.req,
          reqMsg: this.userInfo.userName,
          type: 1,
        })
      )
      this.im_time_interval = window.setInterval(this.im_send, 10000) // 开始每10s发送一次ping消息
      console.log('Connected to WebSocket server.')
    },
    im_onClose(evt) {
      // console.log("Disconnected");
    },
    im_onMessage(evt) {
      const a = JSON.parse(evt.data)

      if (a.type == 1) {
        if (a.bizToId == this.imQunId) {
          this.list.push(a)
          this.$forceUpdate()
          this.scrollbottom()
        }
      }
    },
    im_onError(evt) {
      // console.log("Error occured: " + evt.data);
    },
    im_send() {
      if (this.im_websocket.readyState == WebSocket.OPEN) {
        this.im_websocket.send(
          JSON.stringify({
            // 连接成功将，类型回传给服务端
            requestId: this.req,
            reqMsg: 'ping msg',
            type: 3,
          })
        )
      } else {
        clearInterval(this.im_time_interval)
        if (this.count < 3) {
          // 自动连接3次
          this.im_connect()
        } else {
          console.log('send failed. websocket not open. please check.')
        }
      }
    },
    // 滚动到底
    scrollbottom() {
      this.$nextTick(() => {
        const msgBlock = document.getElementById('msgBlock')
        msgBlock.scrollTop = msgBlock.scrollHeight
      })
    },
  },
}
</script>

<style lang="less" scoped>
.bigBox {
  width: 100%;


  background: #222222;
  overflow: hidden;
  position: relative;
  padding: 32px;


  .aliVideoBar {
    width: 100%;
    height: 617px !important;
    background-color: #222222;
    padding: 32px 0;
    margin-bottom: 20px;
  }

  .videoBar {
    width: 1200px;
    height: 700px;
    background-color: #222222;
    margin: 0 auto;

    // margin-bottom: 20px;
    position: relative;
    display: flex;
    justify-content: space-between;

    .videoWrap {
      border: none;
      width: 1220px !important;
      height: 553px !important;
      margin: auto !important;
      display: flex;
    }

    video {
      width: 100%;
      height: 100%;
    }

    .videoBox {
      border: none;
      width: 100%;
      height: 1100px;
      margin-top: -32px;
      display: flex;
      overflow: hidden;

      .leftVideo {
        width: 880px;
        height: 553px;
        margin-right: 20px;

        .titleBox {
          height: 58px;
          border-radius: 8px 8px 0 0;
          background-color: #383838;
          padding: 19px 20px;
          color: #ffffff;
          display: flex;
          align-items: center;

          .title {
            width: 502px;
            font-size: 20px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            margin-right: 50px;
          }

          .time {
            font-size: 14px;
          }
        }

        .video {
          width: 880px;
          height: 495px;
          border-radius: 0 0 8px 8px;
        }
      }

      .rightBox {
        width: 320px;
        height: 553px;

        .topTitle {
          height: 48px;
          background-color: #444444;
          border-radius: 8px 8px 0 0;
          line-height: 48px;
          color: #ffffff;
          padding-left: 21px;
        }

        .contentList {
          background-color: #383838;
          padding: 20px;
          height: 505px;
          border-radius: 0 0 8px 8px;

          .contentItem {
            height: 76px;
            width: 273px;
            display: flex;
            margin-bottom: 20px;

            .itemImg {
              width: 114px;
              height: 76px;
              border-radius: 4px;
              margin-right: 12px;
            }

            .contentTitleBox {
              width: 147px;
              font-size: 14px;
              color: #ffffff;
              line-height: 22px;
              margin-bottom: 12px;

              .contentTitle {
                word-break: break-all;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 1;
                overflow: hidden;
                margin-bottom: 10px;
              }

              .timeItem {
                color: #999999;
                font-size: 12px;
              }
            }
          }
        }
      }
    }

    .NIM_wrap {
      width: 300px;
      height: 553px;
      position: relative;


      background: #383838;
      border-radius: 8px 8px 0px 0px;

      .title {
        text-align: center;
        width: 100%;
        height: 48px;
        background: #444444;
        border-radius: 8px 8px 0px 0px;
        opacity: 1;
        font-size: 16px;
        font-family: MicrosoftYaHei, MicrosoftYaHei;
        font-weight: normal;
        color: #FFFFFF;
        line-height: 48px;
      }

      .information {
        height: 454px;

        background: #383838;
        overflow: auto;
        display: flex;
        flex-direction: column;

        .white {
          font-size: 13px;
          padding: 5px;


          .wrap {
            .wrap1 {
              display: flex;
              margin-top: 20px;
            }

            .formNick {

              line-height: 21px;
              font-size: 12px;
              font-family: MicrosoftYaHei, MicrosoftYaHei;
              font-weight: normal;
              color: #AAAAAA;
            }

            .text {



              border-radius: 8px;

              background: #222222;
              padding: 12px;
              max-width: 200px;
              display: inline-block;
              word-wrap: break-word;
            }
          }
        }
      }

      .barrageInput {
        width: 300px;
        height: 59px;
        background: #444444;
        border-radius: 0px 0px 8px 8px;
        opacity: 1;

        display: flex;
        justify-content: space-around;
        align-items: center;

        .input {
          width: 78%;
        }

        .mt-10 {
          ::v-deep .icon-xiaolian {
            color: chocolate;
          }
        }
      }
    }

    .weizan {
      width: 90% !important;
      height: 90vh;
    }

    .weizans {
      width: 101% !important;
    }
  }




}

/deep/.videoBox {
  //
  width: calc(100% + 114px) !important;

  .pc-page__footer {
    color: red !important;
  }
}

.dis-start {
  display: flex;
  flex-direction: column;
  align-items: start;
}

.dis-end {
  display: flex;
  flex-direction: column;
  align-items: end;
}

.mainvideo {
  width: 880px !important;
  height: 100%;

  .mainvideo_top {
    width: 100%;
    height: 58px;
    background: #383838;
    border-radius: 8px 8px 0px 0px;
    opacity: 1;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;

    >div:nth-child(1) {
      width: 360px;
      flex-shrink: 0;
      font-size: 22px;
      font-family: MicrosoftYaHei, MicrosoftYaHei;
      font-weight: normal;
      color: #FFFFFF;
    }

    >div:nth-child(2) {
      display: flex;
      align-items: center;
      flex-shrink: 0;

      .time {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #ff5e51;
        line-height: 14px;
        flex-shrink: 0;


        .iconfont {
          font-size: 14px;
          margin-right: 8px;
        }
      }

      .ter-list {
        display: flex;
        align-items: center;

        background: #383838;
        border-radius: 8px 8px 8px 8px;
        opacity: 1;
        margin-left: 20px;
        flex-shrink: 0;


        .ter {
          color: #fff;
          flex-shrink: 0;
          margin-left: 12px;

        }
      }

    }
  }
}

#J_prismPlayer {
  height: 495px !important;
}

img {
  width: 100%;
  height: 100%;
}

.terbox {
  width: 40px;
  height: 40px;
  overflow: hidden;
}

.ter-img {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  display: grid;
}

::v-deep .el-input {
  width: 268px;
  height: 43px;
  background: #333333;
  border-radius: 24px 24px 24px 24px;
  opacity: 1;
  border: none;

}

::v-deep .el-input__inner {

  height: 43px;
  background: #333333;
  border-radius: 24px 0 0 24px;
  border: none;

  opacity: 1;
}

::v-deep .el-input-group__append {
  background: #333333;

  border-radius: 0 24px 24px 0;

  font-size: 14px;
  font-family: MicrosoftYaHei, MicrosoftYaHei;
  font-weight: normal;
  color: #FFFFFF;
  border: none;
  border-left: 1px solid #666;
  cursor: pointer;
}
</style>