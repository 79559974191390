<template>
  <div class="videoRoom">
    <!-- <div> -->
    <!-- 左视频 -->
    <div class="room-left">
      <div class="room-title">
        <div class="flex-c">
          <div class="elp title">
            {{ detail.title }}
          </div>
          <div class="time">
            <i class="iconfont icon-bofangzhongbeifen" />
            直播时间：{{ detail.startTime | format('yyyy.MM.dd HH:mm') }}
          </div>
        </div>
        <div class="detail-ter flex-c">
          <div class="ter-list">
            <div class="terbox">
              <el-image class="ter-img" :src="liveDetail ? liveDetail.tImg : ''" fit="cover" />
            </div>
            <span class="ter ellipsis">讲师：{{ liveDetail ? liveDetail.terName : '' }}</span>
          </div>
        </div>
      </div>
      <!-- 视频上方提示 -->
      <div v-if="tipsShow" class="room-Top">
        <!-- 左侧提示 -->
        <div class="wrapTopsCenTopL">
          <img src="@/assets/img/kecheng/tipsIcon.png">
          <span>
            课程视频版权所有，禁止任何形式得转载！并未经本公司书面许可的使用行为，我公司均保留追究法律责任的权利。
          </span>
        </div>
        <!-- 右侧关闭 -->
        <div class="wrapTopsCenTopR" @click="tipsShow = false">
          <span>关闭</span>
          <img src="@/assets/img/kecheng/classIcon1123.png">
        </div>
      </div>
      <!-- 视频模块： -->

      <div id="J_prismPlayer">
        <jxyplayer v-if="accessKeyId && accessKeySecret" :url="aliVideoUrl" :access-key-id="accessKeyId"
          :access-key-secret="accessKeySecret" :logo-url="copyright.videoLogo" :options="option" @player="crePlayer" />
      </div>

    </div>

    <div v-if="!this.IsPurchase && !(this.isLive == 3 && this.isTrial)" class="haveNot" @touchmove.stop.prevent="stop()">
      <div>
        <img class="imga" src="@/assets/img/kecheng/No1.png">
        <img class="imgb" src="@/assets/img/kecheng/close.png" @click.stop="goBack()">
        <span>您暂未购买该课程哦~</span>
      </div>
    </div>
    <!-- 是否可以观看弹窗 -->
    <div v-if="showLookDetail" class="lookBg">
      <div class="ifLook">
        <div class="prompt">提示</div>
        <div class="contents">
          您当前正在<span>{{ lookDeatil ? lookDeatil.terminal : '' }}</span>观看<span>{{ lookDeatil ? lookDeatil.chapterName : ''
          }}</span>,请关闭后再行观看其他章节。
        </div>
        <div class="button" @click="closeLook">关闭</div>
      </div>
    </div>
  </div>
</template>
<script>
import ratingPopup from '@/components/ratingPopup.vue'
import {
  getLbClassCourseById,
  getvideoSegmentList,
  selectSupplyToStudentStudyRate
} from '@/api/home'
import { getInfo, getDomain, getToken } from '@/api/cookies'
import jxyplayer from '@/components/jxyPlay.vue'

import {
  Know,
  sendLiveVideoHistory,
  playAuth,
  courseCheckPlay,
  judgeIsComment
} from '@/api/know'
const know = new Know()
import { AnswerClient } from '@/api/task/answerClient'
const answerClient = new AnswerClient()
import liveboFangChater from '@/components/Know/liveboFangChater.vue'
import { newWebSocket } from '@/common/utils/newWebSocket.js'
import { getAccessKey } from '@/api/home'
import Vue from 'vue'
export default {
  components: { liveboFangChater, ratingPopup, jxyplayer },
  props: [
    'detail',
    'isLive',
    'userid',
    'roomid',
    'recordid',
    'courseId',
    'classId',
    'showId',
    'type',
    'ccConfig',
    'IsPurchase',
    'classProductLine',
    'status',
    'aliVideoUrl',
    'teacherId',
    'ccLiveid',
    'live',
    'isTrial',
    'backAudition',
    'chapterName',
    'typekc'
  ],
  data() {
    return {
      copyright: {}, // 机构信息
      accessKeySecret: null,
      accessKeyId: null,
      tipsShow: true,
      // 直播 用户名称
      viewername: '',
      option: {
        width: '880px',
        height: '498px',
        url: this.aliVideoUrl,
        type: 'HLS'
      },
      // 公聊
      msgList: [],
      // 输入框
      my_msg: '',
      timer: null,
      liveInfo: {},
      liveDetail: {
        userid: '',
        roomid: '',
        recordid: ''
      }, // 课程详情
      /* 学习记录 */
      setIntervals: null,
      histor: 0, // 历史播放时间
      intervalTimer: null,
      playauth: null, // 阿里加密播放参数
      liveTime: 0,
      firstPlayFlag: true,
      marginLeft: 0,
      player: null,
      timeStamp: null,
      watchTime: 0,
      dialogVisible: false,
      playbackRateList: [
        // { name: '0.5X', value: 0.5 },
        { name: '1.0X', value: 1 },
        { name: '1.5X', value: 1.5 }
      ],
      times: 1,
      fullScreenInfo: false,
      isfullscreen: false,
      ratingPopupShow: false,
      dottingList: [], // 打点视频列表
      dottingId: null, // 选中的打点ID
      finishTime: null, // 选中的打点视频时长 + 已播放时长
      progressMarkers: [], // 进度条打点列表
      isOpen: false,
      currentData: {},
      judgeCommen: null,
      lookDeatil: null,
      showLookDetail: false,
      goBack: false
    }
  },


  async created() {
   

    this.copyright = getDomain()
    this.userInfo = getInfo()
    if (!this.IsPurchase && !(this.isLive == 3 && this.isTrial)) {
      
      return
    }
    getLbClassCourseById(
      this.courseId,
      this.classId && this.typekc != 16 ? this.classId : undefined,
      this.classId && this.typekc == 16 ? this.classId : undefined
    ).then((res) => {
      this.liveDetail = res.data.details
    })
    this.getAccessKey()

  },

  mounted() {

  },

  methods: {
    async getAccessKey() {
      const res = await getAccessKey()
      if (res.code == 0) {
        this.accessKey = res.data
        this.accessKeyId = res.data.accessKeyId
        this.accessKeySecret = res.data.accessKeySecret
      }
    },



  }
}
</script>
<style lang="less" scoped>
// 视频上方提示
.room-Top {
  width: 100%;
  height: 40px;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: absolute;
  top: 58px;
  z-index: 99;

  // 左侧提示
  .wrapTopsCenTopL {
    width: auto;
    height: 16px;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    img {
      width: 16px;
      height: 16px;
      margin: 0px 10px 0px 10px;
    }

    span {
      width: auto;
      height: auto;
      font-size: 12px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #f15817;
    }
  }

  // 右侧关闭
  .wrapTopsCenTopR {
    width: auto;
    height: 12px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    cursor: pointer;

    span {
      width: auto;
      height: auto;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #a8abbe;
    }

    img {
      width: 11px;
      height: 11px;
      margin: 0px 10px 0px 5px;
    }
  }
}

/* 讲师 */
.detail-ter {
  // width: 100%;
  // height: 58px;
  // background: #383838;
  // border-radius: 8px 8px 8px 8px;
  // opacity: 1;
  // padding: 0px 20px;
  // .ter {
  //   color: #fff;
  // }
}

/* 左视频打点 */
.videoRBI {
  height: 174px;
  // width: calc(100% + 20px);
  width: 100%;
  margin-top: 13px;
  display: flex;
  background-color: #222222;
  padding: 10px;

  .arrow {
    width: 16px;
    height: 146px;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
    text-align: center;
    line-height: 146px;
    color: #ffffff;
    cursor: pointer;
  }

  .videoRBIContent {
    width: 832px;
    height: 146px;
    margin: 0 8px;
    overflow: hidden;
    position: relative;

    .videoRBIBox {
      display: flex;
      position: absolute;
      left: 0;
      top: 0;

      .nomore {
        display: flex;
        align-items: center;
        width: 376px;
        height: 122px;
        background: #333333;
        border-radius: 8px;
        margin-left: 8px;
        padding-top: 13px;
        padding-left: 37px;
        font-size: 14px;
        font-family: MicrosoftYaHei;
        color: #c9c9c9;
        line-height: 27px;

        img {
          width: 110px;
          height: 110px;
          margin-right: 16px;
        }
      }

      .onceItem {
        display: flex;
        width: 456px !important;
        height: 122px !important;
        background: #ffffff;
        border-radius: 8px;
        padding-top: 4px;
        padding-left: 6px;

        .img_box {
          position: relative;
          width: 197px !important;
          height: 111px !important;
          margin-right: 15px;

          img {
            width: 100% !important;
            height: 100% !important;
          }

          .dottingBox {
            position: absolute;
            bottom: 0;
            background: transparent !important;
          }
        }

        .name_box {
          width: 231px !important;
          padding: 0 !important;

          .dotting_names {
            width: 231px !important;
            height: 54px;
            font-size: 16px !important;
            font-family: MicrosoftYaHei;
            color: #333333;
            line-height: 27px;
            text-overflow: -o-ellipsis-lastline;
            overflow: hidden; //溢出内容隐藏
            text-overflow: ellipsis; //文本溢出部分用省略号表示
            display: -webkit-box; //特别显示模式
            -webkit-line-clamp: 2; //行数
            line-clamp: 2;
            -webkit-box-orient: vertical;
            margin-bottom: 18px;
          }

          .onceBtn {
            width: 100%;
          }

          .item_btn {
            justify-content: center;
          }
        }
      }

      .contentItem {
        width: 160px;
        height: 146px;
        margin-right: 8px;
        position: relative;

        .img_box {
          width: 160px;
          height: 90px;
          position: relative;

          .itemImg {
            width: 160px;
            height: 90px;
            border-radius: 8px;
            cursor: pointer;
          }

          .nowPlaying {
            width: 64px;
            height: 24px;
            border-radius: 8px 0 8px 0;
            background-color: #ff5e51;
            text-align: center;
            line-height: 24px;
            font-size: 12px;
            color: #ffffff;
            position: absolute;
            top: 0;
            left: 0;
            cursor: pointer;
          }

          .red_borders {
            border: 2px solid #ff5e51;
            border-radius: 8px 8px 8px 8px;
            width: 197px;
            height: 111px;
            position: absolute;
            top: 0;
            left: 0;
          }

          .red_border {
            border: 2px solid #ff5e51;
            border-radius: 8px 8px 0 0;
            width: 160px;
            height: 90px;
            position: absolute;
            top: 0;
            left: 0;
          }

          .dottingBox {
            width: 160px;
            height: 24px;
            border-radius: 0 0 8px 8px;
            background: linear-gradient(180deg,
                rgba(0, 0, 0, 0) 0%,
                rgba(0, 0, 0, 0.6) 100%);
            color: #ffffff;
            line-height: 24px;
            font-size: 12px;
            position: absolute;
            bottom: 0;
            left: 0;
            display: flex;
            justify-content: space-between;
            padding: 0 8px;
            cursor: pointer;
          }
        }

        .name_box {
          background-color: #ffffff;
          width: 160px;
          height: 56px;
          border-radius: 0 0 8px 8px;
          padding: 12px 0 0 12px;

          .dotting_name {
            color: #373a42;
            font-size: 12px;
            width: 132px;
            margin-bottom: 6px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
          }

          .item_btn {
            display: flex;
            align-items: center;
            cursor: pointer;

            .item_img {
              margin-right: 4px;
              width: 10px;
              height: 10px;
            }

            .btn_name {
              color: #ff5e51;
              font-size: 12px;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
}

/* 章节名称 */
.room-title {
  height: 58px;
  background: #383838;
  border-radius: 8px 8px 0px 0px;
  width: 100%;
  overflow: hidden;
  scrollbar-width: none;
  opacity: 1;
  font-size: 22px;
  font-family: Microsoft YaHei-Regular;
  font-weight: 400;
  color: #ffffff;
  line-height: 22px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;

  .title {
    max-width: 460px;
  }

  .time {
    font-size: 14px;
    font-family: Microsoft YaHei-Regular;
    font-weight: 400;
    color: #ff5e51;
    flex-shrink: 0;
    line-height: 14px;
    margin-left: 19px;

    .iconfont {
      font-size: 14px;
      margin-right: 4px;
    }
  }

  .detail-ter {
    color: #ffffff;

    .ter {
      color: #fff;
      line-height: unset;
      max-width: 110px;
    }
  }
}

// 中间视频
.videoRoom {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  width: 100%;
  padding: 32px 0px;
  background: #17151a;

  // 左视频
  .room-left {
    width: 880px;
    height: 100%;
    margin-right: 15px;
    position: relative;

    #playbackPanel {
      width: 100% !important;
      height: 495px;
      border-radius: 0px 0px 10px 10px;
      opacity: 1;
      position: relative;
      overflow: hidden;

      .icon-quanping1 {
        position: absolute;
        top: 20px;
        right: 20px;
        cursor: pointer;
      }

      .playbackRate_box {
        display: none;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: 999999;

        .playbackRate_btn {
          position: absolute;
          top: 10px;
          right: 10px;
          color: #fff;
          width: 80px;
          padding: 5px 0;
          border: 1px solid #fff;
          border-radius: 20px;
          text-align: center;
          cursor: pointer;

          .playbackRate_item_box {
            display: none;
            flex-direction: column;
            justify-content: space-around;
            background-color: rgba(0, 0, 0, 0.5);
            // background-color: red;
            width: 80px;
            height: 100px;
            position: absolute;
            right: 0;
            top: -100px;
            border-radius: 10px;

            .playbackRate_item {
              color: #fff;
            }

            .on {
              color: #00a2e9;
            }
          }
        }

        .playbackRate_btn:hover {
          border: 1px solid #00a2e9;
          color: #00a2e9;
          transition: 100ms;
        }

        .playbackRate_btn:hover .playbackRate_item_box {
          display: flex;
        }
      }
    }

    #playbackPanel:hover .playbackRate_box {
      display: block;
    }

    #J_prismPlayer {
      position: relative;
      height: 495px !important;

      .playbackRate_box {
        display: none;
        position: absolute;
        bottom: 0;
        right: 100px;
        width: 100px;
        height: 50px;
        z-index: 999999;

        .playbackRate_btn {
          position: absolute;
          top: 10px;
          right: 10px;
          color: #fff;
          width: 80px;
          padding: 5px 0;
          border-radius: 20px;
          text-align: center;
          cursor: pointer;

          .playbackRate_item_box {
            display: none;
            flex-direction: column;
            justify-content: space-around;
            background-color: rgba(0, 0, 0, 0.5);
            // background-color: red;
            width: 80px;
            height: 100px;
            position: absolute;
            right: 0;
            top: -100px;
            border-radius: 10px;

            .playbackRate_item {
              color: #fff;
            }

            .on {
              color: #00a2e9;
            }
          }
        }

        .playbackRate_btn:hover {
          color: #00a2e9;
          transition: 100ms;
        }

        .playbackRate_btn:hover .playbackRate_item_box {
          display: flex;
        }
      }
    }

    #J_prismPlayer:hover .playbackRate_box {
      display: block;
    }

    .prism-player {
      width: 100%;
      height: 100%;
    }

    video {
      width: 100%;
      height: 100%;
    }

    .isBottom {
      width: 100%;
      height: 156px !important;
      padding-top: 15px;
      padding-left: 19px;
      background-color: #fff;
      background: #1f1e24;
      border-radius: 8px;

      .videoRBIContent {
        margin: 0 !important;
      }
    }
  }

  // 右头像/聊天
  .room-right {
    height: 100%;
    flex-shrink: 0;
    display: flex;
    flex-direction: column;

    #playbackPlayer {
      width: 275px;
      height: 206px;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      overflow: hidden;
      margin-bottom: 20px;
    }

    img {
      width: 135px;
      height: 113px;
      margin-top: 26px;
    }

    span {
      width: auto;
      height: auto;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #6a6a6a;
    }

    .room {
      width: 320px;
      height: 371px;
      background: #383838;
      border-radius: 8px 8px 8px 8px;
      opacity: 1;
      overflow: hidden;
      overflow-y: scroll;
    }

    .course_box1 {
      height: 465px !important;

      // max-height: 465px !important;
      .course_list {
        height: 465px !important;
        // max-height: 465px !important;
      }
    }

    .course_box2 {
      height: 280px !important;

      // max-height: 280px !important;
      .course_list {
        height: 280px !important;
        // max-height: 280px !important;
      }
    }

    .course_box3 {
      height: 692px !important;

      // max-height: 692px !important;
      .course_list {
        height: 692px !important;
        // max-height: 692px !important;
      }
    }

    .course_box4 {
      height: 506px !important;

      // max-height: 506px;
      .course_list {
        height: 506px !important;
        // max-height: 506px !important;
      }
    }

    .course_box5 {
      height: 450px !important;

      // max-height: 450px;
      .course_list {
        height: 450px !important;
        // max-height: 450px !important;
      }
    }

    .course_box6 {
      height: 670px !important;

      // max-height: 670px;
      .course_list {
        height: 670px !important;
        // max-height: 670px !important;
      }
    }

    .course_box {
      width: 275px;
      height: 513px;
      flex: 1;

      .title_box {
        width: 275px;
        height: 48px;
        border-radius: 8px 8px 0 0;
        line-height: 48px;
        padding-left: 14px;
        background-color: #333333;
        color: #ffffff;
      }

      .course_list {
        width: 275px;
        height: 465px;
        font-size: 14px;
        border-radius: 0 0 8px 8px;
        background-color: #1f1e24;
        overflow-y: auto;

        &::-webkit-scrollbar {
          width: 7px;
          height: 4px !important;
        }

        &::-webkit-scrollbar-thumb {
          background: #333333;
          height: 4px !important;
          border-radius: 4px;
        }

        .course_item {
          min-height: 50px;
          width: 100%;
          line-height: 50px;
          padding-left: 15px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          color: #dddddd;
          border-bottom: 1px solid #313035;
          cursor: pointer;

          .m-r-11 {
            margin-right: 11px;
          }

          .btn_box {
            width: 275px;
            height: 51px;
            background-color: #17151a;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            margin-left: -15px;

            .btn_item {
              width: 68px;
              height: 23px;
              border: 1px solid #d2d2d2;
              border-radius: 4px;
              text-align: center;
              line-height: 23px;
              font-size: 12px;
              color: #ffffff;
              margin-right: 18px;
            }
          }
        }

        .on {
          .title_block {

            i,
            span {
              color: #ff5e51 !important;
            }
          }
        }
      }
    }
  }

  // 聊天框
  .wrapTopsCenCenRC {
    width: 100%;
    height: 346px;

    // 聊天框顶部
    .wrapTopsCenCenRCa {
      width: 100%;
      height: 40px;
      background: #464646;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 14px;
      font-family: Microsoft YaHei;
      font-weight: 400;
      color: #efefef;
    }

    // 聊天框内容
    .wrapTopsCenCenRCb {
      width: 100%;
      height: 260px;
      overflow: auto;

      // overflow-y:scroll;
      // overflow-x:hidden
      // 内容盒子
      .wrapTopsCenCenRCbs {
        width: 85%;
        height: auto;
        margin: 20px auto;
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;

        // 左侧头像
        .wrapTopsCenCenRCbsL {
          width: 32px;
          height: 30px;
          border-radius: 50%;
          margin-right: 10px;

          img {
            width: 100%;
            height: 100%;
            border-radius: 50%;
          }
        }

        // 右侧昵称/留言
        .wrapTopsCenCenRCbsR {
          width: 85%;
          height: auto;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          align-items: flex-start;

          span {
            width: auto;
            height: auto;
            font-size: 12px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #b0b0b0;
          }

          p {
            width: 100%;
            height: auto;
            overflow: hidden;
            font-size: 12px;
            margin-top: 5px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #ffffff;
          }
        }
      }
    }

    // 聊天框底部
    .wrapTopsCenCenRCc {
      width: 100%;
      height: 46px;
      background: #5d5d5d;
      display: flex;
      justify-content: center;
      align-items: center;

      // 内容盒子
      .wrapTopsCenCenRCcs {
        width: 85%;
        height: 30px;
        background: #7a7a7a;
        border-radius: 15px;
        display: flex;
        justify-content: flex-end;
        align-items: center;

        // 输入框
        input {
          width: 70%;
          height: 100%;
          border: none;
          outline: none;
          background: #7a7a7a;
          color: #ffffff;
        }

        // 修改输入框预输入文字
        input::-webkit-input-placeholder {
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #efefef;
        }

        // 发送
        div {
          width: 24%;
          height: 100%;
          background: #a1a1a1;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 0px 15px 15px 0px;
          font-size: 14px;
          font-family: Microsoft YaHei;
          font-weight: 400;
          color: #efefef;
          cursor: pointer;
        }
      }
    }

    //隐藏滚动条
    ::-webkit-scrollbar {
      display: none;
      scrollbar-width: none !important;
      -ms-overflow-style: none;
    }
  }

  #logo {
    position: absolute;
    right: 30px;
    bottom: 50px;

    img {
      // width: 100%;
      height: 40px;
    }
  }
}

.full_screen {
  overflow: hidden !important;
  position: fixed !important;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh !important;
  z-index: 99999;
}

::v-deep .cc-video-cover {
  display: none !important;
}

.lookBg {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  .ifLook {
    width: 500px;
    background-color: #fff;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #ccc;

    .contents {
      width: 380px;
      margin: 20px auto;

      &>span {
        color: red;
      }
    }

    .button {
      width: 120px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 0 auto;
      border-radius: 10px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
}

.dsj {
  position: absolute;
  bottom: -10px;
  border-color: #ffffff transparent transparent transparent;
  border-style: solid;
  border-width: 12px 10px 0 10px;
  height: 0;
  width: 0;
  left: calc(50% - 10px);
}

.title_block {
  display: flex;

  .isTitle {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    -o-text-overflow: ellipsis;
  }
}
</style>
