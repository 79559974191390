<template>
  <div class="scoreEvaluation">
    <div class="coupon-content">
      <div class="coupon-title">
        {{ Belonging == 1 ? "为课程评价" : "为课程评分" }}
        <div class="rate" v-if="Belonging == 2">
          <el-rate class="score" v-model="fromData.score" disabled-void-color="#bbb" :size="70"
            active-color="#FFB11B"></el-rate>
        </div>
      </div>
      <div class="m-t-20 m-b-20" v-if="Belonging == 1">
        <el-select v-if="type == 1" filterable v-model="kcId" placeholder="请选择课程" clearable @change="kcChange">
          <el-option v-for="(item, index) in kcList" :key="index" :label="item.kcName" :value="item.kId" />
        </el-select>
        <el-select v-if="type == 1 && productLine != '1'" class="m-l-20" filterable v-model="yeaster" placeholder="请选择年份"
          clearable @change="getclass">
          <el-option v-for="(item, index) in yearList" :key="index" :label="item.year" :value="item.year" />
        </el-select>
        <el-select v-if="type == 1" class="m-l-20" filterable v-model="couese" placeholder="请选择辅导课程" clearable>
          <el-option v-for="(item, index) in coueseList" :key="index" :label="item.title" :value="item.id" />
        </el-select>
      </div>
      <el-input type="textarea" :rows="4" maxlength="200" show-word-limit placeholder="请输入内容..."
        v-model="fromData.content">
      </el-input>

      <div class="keep-learn keep-check" @click.stop="send()">确认提交</div>
    </div>
    <div class="soreList">
      <div class="sore-item" v-for="(item, index) in list" :key="index">
        <img class="userImg" :src="item.userImg" />
        <div class="sore-content">
          <div class="flex-between-c">
            <div class="userName">{{ item.userName }}</div>
            <el-rate v-if="Belonging == 2" :value="item.score" color="#bbb" disabled :size="30" :margin="8"
              active-color="#FFB11B" />
          </div>
          <div class="addTime">{{ item.addTime }}</div>
          <div class="content">{{ item.content }}</div>
          <div class="kcName" v-if="type == 1">{{ showTypeEnum[item.type] }} · {{ item.name }}</div>
        </div>
      </div>
    </div>
    <div class="nothing" v-if="!list || list.length == 0">
      <!-- <img class="nothing-img" src="@/assets/img/study/noNews.png" mode="aspectFit" /> -->
      <div class="nothing-text">暂无内容哦~</div>
    </div>
    <el-pagination class="pagination" :total="total" :current-page="pageNum" :page-sizes="[8, 16, 24, 40]"
      :page-size="pageSize" :pager-count="5" @current-change="handleCurrentChange" @size-change="handleSizeChange"
      background layout="total, sizes, prev, pager, next">
    </el-pagination>
  </div>
</template>
<script>
import Bus from "@/api/bus";
import Vue from "vue";
import { getInfo, getToken } from "@/api/cookies";
import { Evaluation } from "@/api/scoreEvaluation";
let evaluation = new Evaluation();
import { Know } from "@/api/know";
let know = new Know();
import { showTypeEnum } from "@/api/emun";
export default {
  data() {
    return {
      showTypeEnum: showTypeEnum,
      pageSize: 8,
      pageNum: 1,
      total: 0,
      list: [],
      userInfo: null,
      kcId: null,
      kcList: null,
      yeaster: null,
      yearList: [],
      couese: null,
      canClick: true,
      coueseList: [],
      fromData: {
        content: "",
        score: 5,
      },
    };
  },
  props: {
    Belonging: {
      type: Number,
      default: 1, //所属 1:评价 2：评分
    },
    typeNmae: {
      type: String, //所属页面
    },
    type: {
      type: Number, //所属页面/*   1知识套餐  班型 2直播课程  3点播课程 4公开课程 5资料包 6 试卷包 7：音频 8：图文 9：专栏  11：训练营期 12 试卷 */
    },
    typeId: {
      type: String | Number,
    },
    chapterId: {
      type: String | Number,
    },
    sectionId: {
      type: String | Number,
    },
    IsPurchase: {
      type: Boolean,
    },
    courseData: {
      type: Object,
    },
    productLine: {
      type: String,
    },
  },
  created() {
    this.userInfo = getInfo();
    this.getList();
    if (this.type == 1) {
      this.getyears();
    }
    /* if (this.productLine == '1') {
      this.getclass()
    } */
  },
  methods: {
    getList() {
      if (this.Belonging == 1) {
        evaluation
          .getStudentEvaluateList(
            this.pageSize,
            this.pageNum,
            this.type,
            this.type == 1 ? undefined : this.typeId,
            1,
            undefined,
            undefined,
            undefined,
            undefined,
            this.type == 1 ? this.typeId : undefined
          )
          .then((res) => {
            this.list = res.rows;
            this.total = res.total;
          });
      } else {
        evaluation
          .getPresentScorePage(
            this.pageSize,
            this.pageNum,
            this.type,
            this.typeId,
            this.chapterId ? this.chapterId : undefined,
            this.sectionId ? this.sectionId : undefined
          )
          .then((res) => {
            this.list = res.pSList;
            this.total = res.total;
          });
      }
    },
    /* 获取知识套餐包含课程名 */
    async getyears() {
      if (!this.typeId) {
        return;
      }
      await know.classesTable(this.typeId, this.userInfo.id).then((res) => {
        if (res.code == 0) {
          this.kcList = res.data.array;
          for (let item of this.kcList) {
            let yearNames = Array.from(new Set(item.yearNames)); //去重
            item.chil = [];
            for (let year of yearNames) {
              item.chil.push({ year: year, list: null });
            }
            item.year = res.data.yearNames[0];
          }
        }
      });

      this.$forceUpdate();
    },
    /* 获取 */
    async getclass() {
      let data = [];
      this.couese = null;
      this.coueseList = [];
      if (this.productLine == "1" ? true : this.yeaster) {
        await know
          .classesCourseList(
            this.typeId,
            this.yeaster ? this.yeaster : undefined,
            this.kcId ? this.kcId : undefined,
            this.userInfo.id,
            this.IsPurchase ? 1 : 0
          )
          .then((res) => {
            if (res.code == 0) {
              for (let item of res.data.audio) {
                item.type = 3;
                data.push(item);
              }
              for (let item of res.data.face) {
                item.type = 5;
                data.push(item);
              }
              for (let item of res.data.imageText) {
                item.type = 4;
                data.push(item);
              }
              for (let item of res.data.live) {
                item.type = 1;
                data.push(item);
              }
              for (let item of res.data.video) {
                item.type = 2;
                data.push(item);
              }
            }
          });
        this.coueseList = data;
      }
    },
    /* 选择课程名 */
    kcChange() {
      this.coueseList = [];
      this.yearList = [];
      this.yeaster = null;
      this.couese = null;
      if (this.productLine == "1" && this.kcId) {
        this.getclass();
      }
      for (let item of this.kcList) {
        if (this.kcId == item.kId) {
          this.yearList = item.chil;
          return;
        }
      }
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.getList();
    },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.getList();
    },

    async send() {
      if (!this.canClick) {
        return;
      }
      this.canClick = false;
      let userInfo = getInfo();
      if (!userInfo) {
        Vue.prototype.goLoginView(false);
        this.canClick = true;
        return;
      }
      if (!this.IsPurchase) {
        this.$message({
          showClose: true,
          message: "请先购买课程!",
          type: "warning",
        });
        this.canClick = true;
        return;
      }
      if (
        (!this.fromData.content && this.Belonging == 1) ||
        (!this.fromData.content && this.Belonging != 1 && this.fromData.score != 5)
      ) {
        this.$message({
          showClose: true,
          message: "提交内容不能为空!",
          type: "warning",
        });
        this.canClick = true;
        return;
      }
      let typeId = await this.typeId,
        type = await this.type,
        typeNmae = await this.typeNmae;

      if (this.type == 1) {
        if (!this.couese) {
          this.$message({
            showClose: true,
            message: "尚未选择课程，无法评价",
            type: "warning",
          });

          return;
        }
        for (let item of this.coueseList) {
          if (this.couese == item.id) {
            if (item.type == 1) {
              type = await 2;
            } else if (item.type == 2) {
              type = await 3;
            } else if (item.type == 3) {
              type = await 10;
            } else if (item.type == 4) {
              type = await 7;
            } else if (item.type == 5) {
              type = await 8;
            }
            typeNmae = await item.title;
            typeId = await item.id;
          }
        }
      }
      this.$nextTick(() => {
        if (this.Belonging == 1) {
          evaluation
            .addStudentEvaluate(
              type,
              typeId,
              this.userInfo.id,
              typeNmae,
              this.fromData.content,
              this.userInfo.nickName,
              this.userInfo.img,
              this.userInfo.mobile,
              /* this.userInfo.teacherId ? this.userInfo.teacherId : undefined, */
              this.courseData && this.courseData.teacherId ? this.courseData.teacherId : undefined,
              this.type == 1 ? this.typeId : undefined
            )
            .then((res) => {
              if (res.code == 0) {
                this.getList();
                this.fromData = {
                  content: "",
                  score: 5,
                };
              }
            });
        } else {
          evaluation
            .addStudentScore(
              type,
              typeId,
              this.userInfo.id,
              typeNmae,
              this.fromData.content,
              this.fromData.score,
              this.userInfo.nickName,
              this.userInfo.img,
              this.userInfo.mobile,
              this.chapterId ? this.chapterId : undefined,
              this.sectionId ? this.sectionId : undefined
            )
            .then((res) => {
              if (res.code == 0) {
                this.getList();
                this.fromData = {
                  content: "",
                  score: 5,
                };
              }
            });
        }
      });
      this.canClick = true;
    },
  },
};
</script>

<style lang="less" scoped>
/* 提价 */
.scoreEvaluation {
  padding: 32px;
}

/* 内容 */
.soreList {
  width: 100%;
  margin-top: 26px;

  .sore-item {
    width: 100%;
    display: flex;
    padding: 24px 0px 20px;
    border-bottom: 1px solid #eee;

    .userImg {
      width: 48px;
      height: 48px;
      opacity: 1;
      border-radius: 50%;
      margin-right: 16px;
      flex-shrink: 0;
    }

    .sore-content {
      width: 100%;

      .userName {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #333333;
        line-height: 16px;
        margin-top: 5px;
      }

      .addTime {
        font-size: 14px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #999999;
        line-height: 14px;
        margin-top: 8px;
      }

      .content {
        font-size: 16px;
        font-family: Microsoft YaHei-Regular;
        font-weight: 400;
        color: #666666;
        line-height: 18px;
        margin-top: 21px;
      }

      .kcName {
        margin-top: 20px;
        height: 24px;
        background: #f5f7f9;
        border-radius: 4px 4px 4px 4px;
        opacity: 1;
        font-size: 12px;
        font-family: Microsoft YaHei-Regular, Microsoft YaHei;
        font-weight: 400;
        color: #999999;
        line-height: 24px;
        padding: 0px 8px;
        display: inline-block;
      }
    }
  }
}

/deep/.el-select {
  .el-input__inner,
  input {
    height: 32px !important;
  }

  .el-input__icon {
    line-height: 32px;
  }
}

.rate {
  display: flex;
  justify-content: center;
}

.answer-img {
  position: fixed;
  bottom: 200px;
  right: 16px;

  image {
    width: 48px;
    height: 48px;
  }
}

.coupon-content {
  .coupon-title {
    margin: 0px 0px 26px;
    display: flex;
    align-items: center;

    .rate {
      margin-left: 12px;

      /deep/ .el-rate {
        height: 100%;

        .el-rate__icon {
          font-size: 22px;
        }
      }
    }
  }

  .keep-learn {
    height: 40px;
    line-height: 38px;
    padding: 0px 28px;
    margin: 24px 0px 0px;
    float: right;
  }
}

.pagination {
  margin: 20px auto 0px;
}
</style>
