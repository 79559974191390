/*
 * @Author: 
 * @Date: 2022-12-15 10:38:45
 * @LastEditTime: 2023-01-09 00:14:24
 * @LastEditors: Please set LastEditors
 * @Description: 
 * @FilePath: \OrganizationPC\src\common\utils\newWebSocket.js
 * 
 */
export const newWebSocket = {
  ws: null,
  hertTimer: null, // 用于心跳检测
  active_close: false, // 是否主动关闭
  init: (params) => {
    var ip = process.env.VUE_APP_BASE_WS
    newWebSocket.ws = new WebSocket(`${ip}/webSocket${params.url}`);
    newWebSocket.ws.onopen = (data) => {
      newWebSocket.heartbeat();
      if (params.onopen) {
        params.onopen("webSocket已连接", data);
      }
    };
    newWebSocket.ws.onmessage = (data) => {
      if (params.onmessage) {
        params.onmessage(data.data);
      }
    };
    newWebSocket.ws.onclose = (e) => {
      clearInterval(newWebSocket.hertTimer);
      if (newWebSocket.active_close) {
        if (params.onclose) {
          params.onclose("连接已手动关闭");
        }
      } else {
        newWebSocket.reconnect(params);
         
        if (params.onclose) {
          params.onclose("连接已关闭, 正在重连");
        }
      }
    };
    newWebSocket.ws.onerror = () => {
      clearInterval(newWebSocket.hertTimer);
      if (params.onerror) {
        params.message("连接发生错误！等待五秒后重连");
        setTimeout(() => {
          newWebSocket.reconnect(params);
        }, 5000);
      }
    };
    window.onbeforeunload = () => {
      newWebSocket.close()
    }
  },
  heartbeat: () => {
    console.log("执行心跳");
    if (newWebSocket.hertTimer) {
      clearInterval(newWebSocket.hertTimer);
    }
    newWebSocket.hertTimer = setInterval(function () {
      newWebSocket.ws.send("heartbeat");
    }, 1000 * 3);
  },
  reconnect: (params) => {
    console.log("执行重连");
    newWebSocket.active_close = false;
    if (newWebSocket.ws) {
      newWebSocket.ws.close();
    }
    newWebSocket.init(params);
  },
  close: () => {
    console.log("手动关闭，无需重连");
    clearInterval(newWebSocket.hertTimer);
    newWebSocket.active_close = true;
    newWebSocket.ws.close();
  },
  sendMessage: (mess) => {
    try {
      newWebSocket.ws.send(mess)
    } catch (error) {
       
    }
  }
};