<template>
  <el-popover ref="popoverRef" placement="top-start" width="400" trigger="click">
    <div class="emotionList">
      <div @click="getEmo(idx)" v-for="(item, idx) in faceList" :key="idx" class="emotionItem" v-html="item">
      </div>
    </div>
    <div slot="reference" class="iconfont icon-xiaolian"></div>
  </el-popover>
</template>

<script>
import appData from "@/assets/json/emoji.json";
const changeSelectedText = (textArea, str) => {
  if (window.getSelection) {
    // 非IE浏览器
    textArea.setRangeText(str);
    // 在未选中文本的情况下，重新设置光标位置
    textArea.selectionStart += str.length;
    textArea.focus();
  } else if (document.selection) {
    // IE浏览器
    textArea.focus();
    var sel = document.selection.createRange();
    sel.text = str;
  }
};

export default {
  name: "Emoji",
  props: {
    ifIndex: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      faceList: appData,
    };
  },
  methods: {
    getEmo(idx) {
      if (this.ifIndex) {
        this.$emit("isOk", `【myEmoji：${idx}】`);
      } else {
        this.$emit("isOk", this.faceList[idx]);
      }
      this.$refs.popoverRef.doClose();
    },
  },
};
</script>

<style>
/* el-popover是和app同级的，所以scoped的局部属性设置了无效 */
/* 需要设置全局style */
.el-popover {
  height: 200px;
  width: 400px;
  overflow: scroll;
  overflow-x: auto;
}
</style>

<style scoped>
.iconfont {
  color: #fff;
  font-size: 20px;
}
.emotionList {
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
}
.emotionItem {
  width: 10%;
  font-size: 20px;
  text-align: center;
  cursor: pointer;
}
</style>
