<!--
 * @Description:
 * @Version: 2.0
 * @Author: 姜涛
 * @Date: 2022-08-15 11:21:07
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2022-12-26 10:37:59
 * @FilePath: \OrganizationPC\src\components\ratingPopup.vue
-->
<template>
  <div>
    <div v-if="show">
      <div class="mask_layer" />
      <div class="wrap">
        <i class="iconfont el-icon-close" @click="close" />

        <img class="img" src="@/assets/img/Home/characterIllustration.png" alt="">
        <div class="tips">
          <span>亲爱的同学：</span>
          <div class="eetwo">
            感谢您进行课程评价，此评价与授课质量考核直接关联，请基于真实体验认真对待，感谢
            您的宝贵意见。
          </div>
        </div>
        <div class="content_box">
          <div v-for="(item, index) in list" :key="index" class="evaluation_item">
            <div class="evaluation_content">
              {{ `${index + 1}.${item.title}` }}
            </div>
            <div class="rate_box">
              <el-rate v-model="item.rate" class="m-r-20" :allow-half="true" score-template="{value}"
                @change="fraction(item)" />
              <div>
                <span class="num">{{ item.rate * 2 }} 分</span>
                <span>{{ tips[item.rate * 2 - 1] }}</span>
              </div>
            </div>
            <div v-show="item.rate * 2 < 9" class="opinion_box">
              <div class="title">改进意见</div>
              <el-input v-model="item.remark" :autosize="{ minRows: 3 }" type="textarea" placeholder="请输入10字以上..."
                :rows="3" maxlength="100" show-word-limit />
            </div>
          </div>

          <div v-show="feedback" class="feedback_box">
            <div class="title">
              欢迎您给我们提出宝贵建议（如果教研资料做的不错、老师讲得也不错，不要吝啬您的赞美）
            </div>
            <el-input v-model="suggest" type="textarea" placeholder="请输入10字以上..." :autosize="{ minRows: 3 }"
              maxlength="100" show-word-limit />
          </div>
        </div>
        <div class="btn_box">
          <div class="btn bor" @click="close">取消</div>
          <div class="btn blue" @click="submit">提交</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getInfo } from '@/api/cookies'
import { Evaluation } from '@/api/scoreEvaluation'
const evaluation = new Evaluation()
export default {
  props: [
    'show',
    'type',
    'courseId',
    'chapterId',
    'sectionId',
    'teacherId',
    'IsPurchase'
  ],
  /* props: {
    show: Boolean,
    type: Number, // 1 直播 2录播
    courseId: Number, // 课程id
    chapterId: String, // （直播章id）（录播 章id）
    sectionId: String, // （录播 节id）
    teacherId: Number, // 老师ID
  }, */
  data() {
    return {
      value: 5,
      textarea1: '',
      tips: [
        '较差',
        '较差',
        '一般',
        '一般',
        '合格',
        '合格',
        '良好',
        '良好',
        '优秀',
        '优秀'
      ],

      list: [],
      userInfo: null,
      suggest: '',

      feedback: true
    }
  },
  created() {
    this.userInfo = getInfo()
    this.selectDimensionStuList()
  },
  methods: {
    //   获取评分配置
    selectDimensionStuList() {
      const data = {
        type: 2,
        status: 4
      }
      evaluation.selectDimensionStuList(data).then((res) => {

        res.data.forEach((item) => {
          item.rate = 5
        })
        this.list = res.data
      })
    },
    submit() {
      if (this.IsPurchase == false) {
        this.$message({
          showClose: true,
          message: '请先购买课程!',
          type: 'warning'
        })
        return
      }
      for (let i = 0; i < this.list.length; i++) {
        const item = this.list[i]
        if (item.rate * 2 < 9 && (item?.remark?.length < 10 || !item.remark)) {
          this.$message({
            type: 'error',
            message: '请输入十字以上内容'
          })
          return
        }
      }

      const boo = this.list.every((item) => {
        return item.rate * 2 > 8
      })

      if (boo && (this.suggest.length < 10 || !this.suggest)) {
        this.$message({
          type: 'error',
          message: '请输入十字以上内容'
        })
        return
      }

      const data = {
        terId: this.teacherId,
        type: this.type,
        courseId: this.courseId,
        chapterId: this.chapterId,
        sectionId: this.sectionId ? this.sectionId : null,
        stuId: this.userInfo.id,
        stuName: this.userInfo.nickName,
        stuImg: this.userInfo.img,
        stuAccount: this.userInfo.mobile
          ? this.userInfo.mobile
          : this.userInfo.account,
        source: 'PC',
        suggest: this.suggest ? this.suggest : null,
        tenantId: this.userInfo.tenantId
      }

      data.scoreContent = this.list.map((item) => {
        return {
          title: item.title,
          score: item.rate * 2,
          remark: item.remark
        }
      })
      data.scoreContent = JSON.stringify(data.scoreContent)
      data.scoreNum = this.list.reduce((prev, cur) => {
        return prev + cur.rate * 10 * 2 * (Number(cur.gdp) / 100)
      }, 0)

      evaluation.addPcTeacherScore(data).then((res) => {
        if (res.code == 0) {
          this.$message({
            type: 'success',
            message: res.msg
          })
          this.close()
        } else {
          this.$message({
            type: 'error',
            message: res.msg
          })
        }
      })
    },
    fraction(val) {

      this.feedback = this.list.every((item) => {
        return item.rate * 2 > 8
      })
    },
    close() {
      this.$emit('close')
    }
  }
}
</script>

<style lang="less" scoped>
.mask_layer {
  background-color: #000000;
  opacity: 0.7;
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1999;
}

.wrap {
  position: fixed;
  transform: translate(-50%, -50%);
  z-index: 1999;
  top: 50%;
  left: 50%;
  width: 531px;
  height: 619px;
  border-radius: 12px;
  background-image: url("~@/assets/img/Home/ratingPopupBackImg.png");
  padding-top: 47px;

  .el-icon-close {
    position: absolute;
    right: 12px;
    top: 12px;
    color: #ffffff;
    font-size: 14px;
    cursor: pointer;
  }

  .img {
    width: 134px;
    height: 113px;
    position: absolute;
    right: 39px;
    top: -53px;
  }

  .tips {
    color: #00144b;
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    width: 492px;
    margin-bottom: 15px;
    margin-left: 20px;

    .eetwo {
      text-indent: 2em;
    }
  }

  .content_box {
    width: 467px;
    height: 412px;
    border-radius: 20px;
    background: linear-gradient(360deg, #f5f7ff 0%, #f5f7ff 100%);
    box-shadow: inset 0px 0px 60px 1px #8eabff;
    margin: auto;
    margin-bottom: 24px;
    overflow: auto;
    padding: 20px 28px 28px 28px;

    .evaluation_item {
      margin-bottom: 12px;

      .evaluation_content {
        color: #333333;
        font-size: 12px;
        font-weight: 500;
        line-height: 16px;
      }

      .rate_box {
        display: flex;
        align-items: center;
        font-size: 12px;
        margin-top: 8px;

        .m-r-20 {
          margin-right: 20px;
        }

        .num {
          color: #1d68ff;
          margin-right: 10px;
        }
      }

      .opinion_box {
        .title {
          color: #333333;
          font-size: 12px;
          font-weight: 500;
          margin-bottom: 8px;
        }

        .title::before {
          content: "*";
          color: #ff0000;
          margin-right: 5px;
        }
      }
    }

    .feedback_box {
      color: #333333;
      font-size: 12px;

      .title {
        margin-bottom: 12px;
      }
    }

    .title::before {
      content: "*";
      color: #ff0000;
      margin-right: 5px;
    }
  }

  .btn_box {
    display: flex;
    justify-content: space-evenly;

    .btn {
      width: 190px;
      height: 40px;
      border-radius: 4px;
      background-color: #ffffff;
      color: #333333;
      font-weight: 500;
      font-size: 14px;
      text-align: center;
      line-height: 40px;
      cursor: pointer;
    }

    .blue {
      background-color: #1d68ff;
      color: #ffffff;
    }

    .bor {
      border: 1px solid #dddddd;
      box-sizing: border-box;
    }
  }
}
</style>
